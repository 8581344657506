import React, { useState } from 'react'
import { BannerPlatform, BannerType } from '@prisma/client/react-native'
import isNil from 'lodash.isnil'
import { trpc } from '@centrito/app/api/trpc'
import { BaseImageSlider, type ImageSources } from '@centrito/app/components/BaseImageSlider'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import { Spinner, XStack } from '@centrito/ui/src'

interface HomeBannerSliderProps {
  platform: string
  subhome?: string
}

export const HomeBannerSlider: React.FC<HomeBannerSliderProps> = ({
  platform,
  subhome,
}): JSX.Element => {
  const { data } = trpc.catalog.homeBanner.findMany.useQuery()
  const { width: _windowWidth } = useWindowDimensions()
  const windowWidth = _windowWidth - 12 * 2
  const height = windowWidth * 0.45
  const banners: ImageSources = isNil(data)
    ? []
    : data.filter((banner) => banner.categoryNodesPrefix === subhome).length > 0
      ? (data
          .filter((banner) => banner.categoryNodesPrefix === subhome)
          .map((banner) => ({
            url: banner.imageUrl,
            urlWebP: banner.imageUrlWebP,
            alt: banner.alt,
            href: banner.href,
          })) ?? [])
      : data
          .filter(
            (banner) =>
              banner.type === BannerType.SLIDER &&
              (banner.platform === BannerPlatform.BOTH || banner.platform === platform),
          )
          .filter(
            (banner) => isNil(banner.categoryNodesPrefix) || banner.categoryNodesPrefix === '',
          )
          .map((banner) => ({
            url: banner.imageUrl,
            urlWebP: banner.imageUrlWebP,
            alt: banner.alt,
            href: banner.href,
          }))

  const [imageIndex, setImageIndex] = useState<number>(0)

  const nextImage = (): void => {
    setImageIndex((imageIndex + 1) % banners.length)
  }
  const prevImage = (): void => {
    setImageIndex((imageIndex + banners.length - 1) % banners.length)
  }
  if (banners.length === 0) {
    return (
      <XStack width="100%" height={height} justifyContent="center" alignItems="center">
        <Spinner size="large" color="$samsamBlue" />
      </XStack>
    )
  }

  return (
    <XStack borderRadius={12} gap={12}>
      <BaseImageSlider
        automaticScrollTime={5000}
        imageSources={banners}
        imageIndex={imageIndex}
        setImageIndex={setImageIndex}
        nextImage={nextImage}
        previousImage={prevImage}
        gap={6}
        margin={10}
        borderRadius={8}
        hasCounterDots={false}
        aspectRatio={2.2222222}
        isMarginTopDisabled
        isHome
        subhome={subhome}
      />
    </XStack>
  )
}
